import React from "react";
import styled from "styled-components";
import { PageProps, graphql } from "gatsby";

import IngredientsBar from "@components/recipes/ingredientsBar";
import Methods from "@components/recipes/methods";
import RecipeHero from "@components/recipes/recipeHero";
import RelatedProduct from "@components/recipes/relatedProduct";
import { Query, SanityMetaImage } from "@graphql-types";
import { TABLET_BREAKPOINT } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { Container } from "@util/standard";
import { isSanityColumnContent, isSanityLearnCategoryPreview, isSanityRecipePreview, isSanityVideo } from "@util/types";
import ColumnContent from "@components/global/columnContent";
import LearnCategoryPreview from "@components/global/learnCategoryPreview";
import { Video } from "@components/global";
import RecipePreview from "@components/global/recipePreview";
import RelatedRecipe from "@components/recipes/relatedRecipe";
import SEO from "@shared/seo";
import { useOurPicksQuery } from "@src/query";
import Layout from "@shared/layout";
import recipesQuery from "@src/query/recipesQuery";

interface Props extends PageProps {
  data: Query;
}

const StickyContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 66px;
  width: 40%;
  margin-right: 10%;
  margin-left: 15px;
  margin-bottom: 15px;
  height: 100%;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-left: 0px;
    position: initial;
    width: 100%;
  }
`;

export default function RecipeInnerTemplate({ data }: Props) {
  const { nodes } = data.allSanityRecipe;

  const recipe = nodes[0];
  const { isLaptopWidth } = useCheckScreenWidth();

  const allSanityOurPicks = useOurPicksQuery();
  let hasRelatedRecipes = false;

  if (recipe == null) {
    return null;
  }

  return (
    <>
      <SEO
        seoData={recipe.seo}
        overwriteImage={
          recipe.seo && recipe.seo.ogImage && recipe.seo.ogImage.asset && recipe.seo.ogImage?.asset.url
            ? null
            : recipe.featureImage
        }
        slug={`/recipes/${recipe.slug?.current}`}
      />
      <Layout>
        <RecipeHero recipe={recipe} />
        {recipe.productRef && <RelatedProduct data={recipe.productRef} />}
        <Container width="100%" flexDirection={isLaptopWidth ? "row" : "column"}>
          <StickyContainer>
            {recipe.ingredients && <IngredientsBar data={recipe.ingredients} utensils={recipe.utensils} />}
          </StickyContainer>
          <Container width="50%" tabletWidth="100%">
            {recipe.methods && <Methods data={recipe.methods} />}
          </Container>
        </Container>
        {recipe.suggestedRecipe && <RelatedRecipe data={recipe.suggestedRecipe} />}
        <div>
          {recipe.pageContent &&
            recipe.pageContent.map(data => {
              if (data == null) {
                return null;
              }
              if (isSanityVideo(data)) {
                return (
                  <Container
                    position="relative"
                    height="550px"
                    tabletHeight="400px"
                    width="100%"
                    margin="120px auto"
                    tabletMargin="80px auto"
                    zIndex={5}
                    key={data._key}
                  >
                    <Video
                      url={data.url as string}
                      id={data.url as string}
                      useThumbnail
                      thumbnail={data.image as SanityMetaImage}
                      controls
                      volume
                    />
                  </Container>
                );
              }

              if (isSanityColumnContent(data)) {
                return <ColumnContent data={data} />;
              }

              if (isSanityRecipePreview(data)) {
                hasRelatedRecipes = true;
                return <RecipePreview data={data} />;
              }

              if (isSanityLearnCategoryPreview(data)) {
                return <LearnCategoryPreview data={data} />;
              }
              return null;
            })}

          {/* Add default recipes block if no related recipes are found */}
          {!hasRelatedRecipes && allSanityOurPicks && <RecipePreview data={allSanityOurPicks} />}
        </div>
      </Layout>
    </>
  );
}

export const query = graphql`
  query recipeQuery($slug: String) {
    allSanityRecipe(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        ...sanityRecipe
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Image, Video } from "@components/global";
import ModalBox from "@components/global/modalBox";
import ShareButtons from "@components/global/shareButtons";
import { SanityRecipe } from "@graphql-types";
import { colors, colorsRGB, gradients, icons } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { Container, H2, P, A, Gradient } from "@util/standard";
import { handleSaveRecipe, isBrowser } from "@util/helper";
import { navigate } from "gatsby";
import { useStore } from "@state/store";
import YoutubeVideo from "@components/global/youtubeVideo";
import RatingBlock from "./ratingBlock";

interface Props {
  recipe: SanityRecipe;
}

const CookTimeWrapper = styled(Container)`
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid ${colorsRGB.black(0.2)};
  width: 100%;
  max-width: 120px;
  margin-right: 15px;
`;

const BackButton = styled(A)`
  width: 82%;
  margin: 0 auto 15px auto;
`;

const Icon = styled.img<{ margin?: string; height?: string; width?: string }>`
  filter: brightness(0);
  width: 16px;
  height: 16px;
  ${({ height }) => height && `height: ${height};`};
  ${({ width }) => width && `width: ${width};`};

  margin: 8px auto;
  ${({ margin }) => margin && `margin: ${margin};`};
`;

const Categories = styled(Container)`
  border-bottom: 1px solid ${colorsRGB.black(0.2)};
  flex-wrap: wrap;
  padding: 20px 0;
`;

const Category = styled(P)`
  background: ${colorsRGB.darkerGreen(0.2)};
  color: ${colors.darkerGreen};
  padding: 7px 12px;
  border-radius: 3px;
  margin: 10px 10px 0px 0;
`;

const SaveIcon = styled.img`
  width: 14px;
  height: 20px;
  margin: auto;
`;

function RecipeHero({ recipe }: Props) {
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [isSaved, setIsSaved] = useState<boolean>();
  const { user, savedRecipes, isLoggedIn } = useStore();

  const { isLaptopWidth } = useCheckScreenWidth();
  const recipeMatch = () => {
    const recipeId = recipe._id;
    const recipeMatches = savedRecipes?.filter(recipe => recipe === recipeId);
    recipeMatches && recipeMatches.length > 0 ? setIsSaved(true) : setIsSaved(false);
  };

  const checkSaved = () => {
    if (!isLoggedIn) {
      useStore.setState({ authMenuOpen: true });
    }
    if (user == null || recipe._id == null || user.uid == null) {
      return null;
    }
    handleSaveRecipe({
      id: recipe._id,
      savedRecipes: savedRecipes ? savedRecipes : [],
      isUserSignedIn: true,
      userUId: user.uid,
    });
    return;
  };

  useEffect(() => {
    recipeMatch();
  });

  return (
    <Container margin="9% 0 100px 0" tabletMargin="0" flexDirection="column">
      {isLaptopWidth && (
        <BackButton onClick={() => (isBrowser() ? window.history.back() : navigate("/recipes"))}>{`< Back`}</BackButton>
      )}
      <Container width="82%" tabletWidth="100%" margin="auto" flexDirection={isLaptopWidth ? "row" : "column"}>
        <Container width="65%" tabletWidth="100%" height={!isLaptopWidth && "500px"} position="relative">
          <Container
            onClick={() => checkSaved()}
            cursor="pointer"
            backgroundColor={isSaved ? "wanakaBlue" : "pink"}
            width="150px"
            height="50px"
            position="absolute"
            bottom="0px"
            right="0px"
            justifyContent="space-evenly"
            zIndex={3}
          >
            <Container width="10%">
              <SaveIcon src={isSaved ? icons.saved : icons.unSaved} />
            </Container>
            <P color="white">{isSaved ? "Unsave Recipe" : "Save Recipe"}</P>
          </Container>
          <Image objectFit="cover" image={recipe.featureImage?.asset?.gatsbyImageData} />
          {!isLaptopWidth && <Gradient gradient={gradients.gradientFromTop} />}
        </Container>
        <Container
          width="35%"
          tabletWidth="80%"
          padding="3% 0 0 9%"
          mobilePadding="0"
          tabletMargin="60px auto 0 auto"
          flexDirection="column"
        >
          <H2 color="darkerGreen">{recipe.recipeName}</H2>
          <P margin="15px 0 20px 0">{recipe.description}</P>
          {/* <RatingBlock recipeId={recipe._id} /> */}
          <Container width="100%" margin="50px 0 60px 0" tabletMargin="50px 0 40px 0" mobileMargin="20px 0 15px 0">
            {(recipe.prepTime || recipe.prepTimeText) && (
              <CookTimeWrapper>
                <P margin="20px 0 0 0" textAlign="center" fontSize={13} tabletFontSize={13}>
                  Prep Time
                </P>
                <Icon src={icons.prepTime} />
                <Container width="80%" flexDirection="column" margin="0 auto 20px auto">
                  {recipe.prepTime && (
                    <P noMargin textAlign="center" fontSize={13} tabletFontSize={13}>
                      {recipe.prepTime} mins
                    </P>
                  )}
                  {recipe.prepTimeText && (
                    <P noMargin textAlign="center" fontSize={13} tabletFontSize={13}>
                      {recipe.prepTimeText}
                    </P>
                  )}
                </Container>
              </CookTimeWrapper>
            )}
            {(recipe.cookTime || recipe.cookTimeText) && (
              <CookTimeWrapper>
                <P margin="20px 0 0 0" textAlign="center" fontSize={13} tabletFontSize={13}>
                  Cook Time
                </P>
                <Icon src={icons.cookTime} />
                <Container flexDirection="column" width="80%" margin="0 auto 20px auto">
                  {recipe.cookTime && (
                    <P noMargin textAlign="center" fontSize={13} tabletFontSize={13}>
                      {recipe.cookTime} mins
                    </P>
                  )}
                  {recipe.cookTimeText && (
                    <P noMargin textAlign="center" fontSize={13} tabletFontSize={13}>
                      {recipe.cookTimeText}
                    </P>
                  )}
                </Container>
              </CookTimeWrapper>
            )}
            {(recipe.servingSize || recipe.servingSizeText) && (
              <CookTimeWrapper>
                <P margin="20px 0 0 0" textAlign="center" fontSize={13} tabletFontSize={13}>
                  Serves
                </P>
                <Icon src={icons.servings} />
                <Container flexDirection="column" width="80%" margin="0 auto 20px auto">
                  <P noMargin textAlign="center" fontSize={13} tabletFontSize={13}>
                    {recipe.servingSize}
                  </P>
                  {recipe.servingSizeText && (
                    <P noMargin textAlign="center" fontSize={13} tabletFontSize={13}>
                      {recipe.servingSizeText}
                    </P>
                  )}
                </Container>
              </CookTimeWrapper>
            )}
          </Container>
          {recipe.recipeCategories && (
            <Categories>
              {recipe.recipeCategories.map(category => {
                if (category == null || category.name == null) {
                  return null;
                }
                return <Category key={category?.id}>{category?.name}</Category>;
              })}
            </Categories>
          )}
          {recipe.featureImage && <ShareButtons pinterestMedia={recipe.featureImage?.asset?.url} />}

          {recipe.recipeVideo && recipe.recipeVideo.url && (
            <>
              <Container
                width="max-content"
                margin="80px 0 0 0"
                tabletMargin="60px 0"
                onClick={() => setPopUpVisible(true)}
              >
                <Icon width="8px" height="11px" margin="auto" src={icons.video} />
                <P fontWeight="semibold" underlined margin="0 0 0 10px">
                  Watch the video
                </P>
              </Container>
              {popUpVisible && (
                <ModalBox isVideo visible={popUpVisible} setVisible={setPopUpVisible}>
                  <Video url={recipe.recipeVideo.url} id={recipe.recipeVideo.url} />
                </ModalBox>
              )}
            </>
          )}
          {recipe.recipeVideoYoutube && recipe.recipeVideoYoutube.embedLink && (
            <>
              <Container
                width="max-content"
                margin="80px 0 0 0"
                tabletMargin="60px 0"
                height="max-content"
                onClick={() => setPopUpVisible(true)}
              >
                <Icon width="8px" height="11px" margin="auto" src={icons.video} />
                <P cursor="pointer" fontWeight="semibold" underlined margin="0 0 0 10px">
                  Watch the video
                </P>
              </Container>
              {popUpVisible && (
                <ModalBox isVideo visible={popUpVisible} setVisible={setPopUpVisible}>
                  <YoutubeVideo youtubeEmbedLink={recipe.recipeVideoYoutube.embedLink} />
                </ModalBox>
              )}
            </>
          )}
        </Container>
      </Container>
    </Container>
  );
}

export default RecipeHero;
